import Marketing from "../assets/images/marketing.svg";
import MeetingTeam from "../assets/images/meeting-team.svg";
import Graduation from "../assets/images/graduation.svg";
import Book from "../assets/images/book.svg";
import Approved from "../assets/images/approved.svg";
import Headphones from "../assets/images/headphones-customer-support-human 1.svg";

export const ReasonNav = [
  {
    id: 1,
    img: Marketing,
    title: "Highly skilled and DEDICATED",
    description:
      "Our tutors are rigorously selected and are some of the best in the country.",
  },
  {
    id: 2,
    img: MeetingTeam,
    title: "GOAL ORIENTATED LESSONS",
    description:
      "Whatever your child wants to achieve, you best believe we’ll help to achieve that.",
  },
  {
    id: 3,
    img: Graduation,
    title: "Chasing the best results",
    description:
      "Our goal is to increase, improve and develop the academic capacity of all our students.",
  },
  {
    id: 4,
    img: Book,
    title: "Passionate about teaching",
    description:
      "Our tutors are eager to make a difference and be an inspiration to students.",
  },
  {
    id: 5,
    img: Approved,
    title: "WEEKLY STUDY PLAN",
    description:
      "Every child gets access to our weekly study plan to keep track of progress.",
  },
  {
    id: 6,
    img: Headphones,
    title: "ALL-ROUND SUPPORT",
    description:
      "Our tutors provide both academic and personal development support to students.",
  },
];
