import WebinarOne from "../assets/images/image 1.svg";
import WebinarTwo from "../assets/images/webinar-2.svg";
import WebinarThree from "../assets/images/webinar-3.svg";
import WebinarFour from "../assets/images/webinar-4.svg";
import WebinarFive from "../assets/images/webinar-5.svg";

export const WebinarContent = [
  {
    id: 1,
    image: WebinarOne,
    description: "NovaTuition: Learning in Lockdown Webinar",
    date: "Sun, 29th November 2021",
  },
  {
    id: 2,
    image: WebinarTwo,
    description: "The Ultimate GCSE and A-Level ‘How to Revise’ Q&A",
    date: "Sun, 7th February 2021",
  },
  {
    id: 3,
    image: WebinarThree,
    description: "NovaTuition: Learning in Lockdown with Saalihah",
    date: "Sun, 29th November 2020",
  },
  {
    id: 4,
    image: WebinarFour,
    description: "NovaTuition: Learning in Lockdown - The 2-Day Event",
    date: "27th - 29th November 2020",
  },
  {
    id: 5,
    image: WebinarFive,
    description: "NovaTuition: Learning in Lockdown",
    date: "27th November 2020",
  },
];
