import React, { useRef } from "react";
import { Row, Container, Col, Form, Button } from "react-bootstrap";
import styles from "./About.module.scss";
import HeroImage from "../../assets/images/Hero Image.svg";
import BallImage from "../../assets/images/Rectangle 34.png";
import PeopleImage from "../../assets/images/Group 66.png";
import Solution from "../../assets/images/Group 6.png";
import Ama from "../../assets/images/Mask.png";
import Demi from "../../assets/images/Mask (1).png";
import Jo from "../../assets/images/Mask (2).png";
import FormImage from "../../assets/images/grades-17 1 (1).png";
import emailjs from "emailjs-com";
import axios from "axios";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import Footer from "../../components/footer/Footer";
import Ribbon from "../../assets/images/Ribbon 1.svg";
import Kiyara from "../../assets/images/kiyara.jpeg";
import PurpleCircle from "../../assets/images/purple-ellipse.svg";
import PurpleTriangle from "../../assets/images/purple-triangle.svg";

const About = () => {
  const dispatch = useDispatch();

  const sendEmail = (e: any) => {
    e.preventDefault();

    const payload = {
      service_id: "service_7pk6gly",
      template_id: "template_wfrmles",
      user_id: "user_bh3URV5GLSVa2h7a9ahmZ",
    };

    axios
      .post("https://api.emailjs.com/api/v1.0/email/send", payload)
      .then((res) => {
        dispatch(
          openToastAndSetContent({
            toastContent: "Your mail has sent successfully",
            toastStyles: {
              backgroundColor: "#1A2578",
              color: "#fff",
            },
          })
        );
        console.log("res", res);
      })
      .catch((err) => console.log("err", err));
  };

  return (
    <div>
      <Container>
        <Row style={{ paddingTop: "40px" }}>
          <Col lg={true}>
            {" "}
            <div className={styles.heroSection}>
              <div className={styles.heroLeft}>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "32px",
                    lineHeight: "39px",
                    letterSpacing: "0.02em",
                    textAlign: "start",
                    color: "#1A2578",
                    marginBottom: "20px",
                  }}
                >
                  Helping your child achieve their highest ambitions one goal
                  and one lesson at a time
                </div>
              </div>
              <div
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "18px",
                  lineHeight: "32px",
                  textAlign: "start",
                  letterSpacing: "0.03em",
                  color: "#1A2578",
                }}
              >
                Tuition and the development of personal attributes such as
                Organisation, Resilience and Self-Discipline are vital in
                improving students' grades and life in general. NovaTuition is
                proud to offer an online network of excellent tutors from Top UK
                Universities to encourage and educate pupils
              </div>
            </div>
          </Col>
          <Col
            lg={true}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div className={styles.heroImageSection}>
              <img src={BallImage} alt="Hero" className={styles.heroImage} />
            </div>
          </Col>
        </Row>
      </Container>
      <div
        style={{
          backgroundColor: "#FCBA48",
          marginTop: "30px",
          paddingTop: "40px",
        }}
      >
        <Container>
          <Row style={{ paddingBottom: "50px" }}>
            <Col lg={true}>
              <div className={styles.heroImageSection}>
                <img
                  src={PeopleImage}
                  alt="Hero"
                  className={styles.heroImage}
                />
                <div className={styles.purpleCircle}>
                  <img src={PurpleCircle} alt="purple" />
                </div>
              </div>
            </Col>
            <Col lg={true}>
              {" "}
              <div className={styles.heroSection}>
                <div className={styles.heroLeft}>
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "32px",
                      lineHeight: "39px",
                      letterSpacing: "0.02em",
                      textAlign: "center",
                      color: "#1A2578",
                      marginBottom: "20px",
                    }}
                  >
                    The Challenges
                  </div>
                </div>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "18px",
                    lineHeight: "32px",
                    textAlign: "start",
                    letterSpacing: "0.03em",
                    color: "#1A2578",
                  }}
                >
                  We know that only high-quality teaching is not enough. A lot
                  of students tell us they have good teaching. However, they
                  still feel like they may not perform in the exams because they
                  lack the organisational skills or the tenacity to go home to
                  reinforce and revise this information.
                </div>
                <div className={styles.purpleTriangle}>
                  <img src={PurpleTriangle} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: "#1A2578" }}>
        <Container>
          <Row style={{ paddingBottom: "50px", paddingTop: "50px" }}>
            <Col lg={true}>
              {" "}
              <div className={styles.heroSection}>
                <div className={styles.heroLeft}>
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "32px",
                      lineHeight: "39px",
                      letterSpacing: "0.02em",
                      textAlign: "center",
                      color: "#ffffff",
                      marginBottom: "20px",
                    }}
                  >
                    Our Solution
                  </div>
                </div>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "18px",
                    lineHeight: "32px",
                    textAlign: "start",
                    letterSpacing: "0.03em",
                    color: "#FFFFFF",
                  }}
                >
                  Therefore, our tutors spend time developing our students for
                  academic success while simultaneously enhancing their personal
                  attributes.
                </div>
                <div
                  style={{
                    position: "absolute",
                    left: "-1rem",
                    bottom: "0rem",
                  }}
                >
                  <img src={PurpleCircle} alt="purple" />
                </div>
              </div>
            </Col>
            <Col
              lg={true}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div className={styles.heroImageSection}>
                <img src={Solution} alt="Hero" className={styles.heroImage} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row style={{ paddingBottom: "30px", paddingTop: "50px" }}>
            <Col sm={12} md={4}>
              <div className={styles.heroImageSection}>
                <Row>
                  <Col md={6}>
                    <img src={Ama} alt="Hero" className={styles.heroImage} />
                  </Col>
                  <Col md={6}>
                    <img src={Demi} alt="Hero" className={styles.heroImage} />
                  </Col>
                  <Col md={6} style={{ marginTop: "15px" }}>
                    <img src={Jo} alt="Hero" className={styles.heroImage} />
                  </Col>
                  <Col
                    md={6}
                    style={{
                      marginTop: "15px",
                    }}
                  >
                    <img src={Kiyara} alt="Hero" className={styles.kiyaImage} />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm={12} md={8}>
              {" "}
              <div className={styles.heroSection}>
                <div className={styles.heroLeft}>
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "32px",
                      lineHeight: "39px",
                      letterSpacing: "0.02em",
                      textAlign: "center",
                      color: "#1A2578",
                      marginBottom: "20px",
                    }}
                  >
                    A word from our management team
                  </div>
                </div>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "18px",
                    lineHeight: "32px",
                    textAlign: "start",
                    letterSpacing: "0.03em",
                    color: "#1A2578",
                  }}
                >
                  <div
                    style={{ position: "absolute", right: "0rem", top: "0rem" }}
                  >
                    <img src={PurpleTriangle} />
                  </div>
                  We started NovaTuition with the aim to help all students reach
                  their full potential. NovaTuition enables students to develop
                  lifelong skills and achieve their desired grades. We believe
                  in young people, in their potential, ability and in the
                  contribution they can and will make in the world. We do this
                  by offering affordable and accessible life-changing online
                  1-to-1 tuition and personal development training for our
                  students and the development of personal attributes. We hope
                  to see you on board!
                  <div className={styles.ama}>AMA, DEMI, JO and KIYARA</div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: "rgba(196, 202, 255, 0.15)" }}>
        <Container>
          <Row style={{ paddingBottom: "30px", paddingTop: "50px" }}>
            <Col
              lg={true}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className={styles.heroImageSection}>
                <img src={FormImage} alt="Hero" className={styles.heroImage} />
              </div>
            </Col>
            <Col lg={true}>
              {" "}
              <div className={styles.formSection}>
                <div className={styles.ribbon}>
                  <img src={Ribbon} alt="ribbon" />
                </div>
                <div className={styles.formBook} style={{ width: "100%" }}>
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "24px",
                      lineHeight: "29px",
                      letterSpacing: "0.02em",
                      color: "#1A2578",
                    }}
                  >
                    Book a quick call with our tutors
                  </div>
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "16px",
                      lineHeight: "19px",
                      letterSpacing: "0.02em",
                      marginTop: "6px",
                      color: "#1A2578",
                    }}
                  >
                    Discuss your wants with us.
                  </div>
                </div>
                <Form onSubmit={sendEmail} style={{ width: "100%" }}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "17px",
                        /* identical to box height */
                        letterSpacing: "0.05em",
                        textTransform: "uppercase",

                        /* Primary Color */
                        color: "#1A2578",
                        marginTop: "20px",
                      }}
                    >
                      Full Name
                    </Form.Label>
                    <Form.Control type="email" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "17px",
                        /* identical to box height */
                        letterSpacing: "0.05em",
                        textTransform: "uppercase",

                        /* Primary Color */
                        color: "#1A2578",
                      }}
                    >
                      Email{" "}
                    </Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "17px",
                        /* identical to box height */
                        letterSpacing: "0.05em",
                        textTransform: "uppercase",

                        /* Primary Color */
                        color: "#1A2578",
                      }}
                    >
                      Message
                    </Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default About;
