import React from "react";
import styles from "./Toptabs.module.scss";
import Logo from "../../assets/images/logo1.png";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { Link, NavLink, useHistory } from "react-router-dom";

const NavBar = () => {
  const history = useHistory();
  return (
    <Navbar collapseOnSelect expand="lg" style={{ background: "#1A2578" }}>
      <Container>
        <Navbar.Brand
          href=""
          style={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "26px",
            lineHeight: "35px",
            color: "#FFFFFF",
          }}
        >
          <img
            src={Logo}
            width="34"
            height="34"
            className="d-inline-block align-top"
            alt="Novatuition logo"
            style={{ marginTop: "-4px" }}
          />
          NovaTuition
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <Nav.Link onClick={() => history.push("/")}>
              <NavLink
                exact
                to="/"
                className={styles.navNormal}
                activeClassName={styles.activeClass}
              >
                Home
              </NavLink>
            </Nav.Link>
            <NavDropdown
              title="Our Services"
              id="collasible-nav-dropdown"
              className={styles.navNormal}
              style={{ color: "#fff !important", display: "flex !important" }}
            >
              <NavDropdown.Item onClick={() => history.push("/private")}>
                <NavLink
                  exact
                  to="/private"
                  className={styles.dropNavNormal}
                  activeClassName={styles.activeClass}
                >
                  Tuition
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => history.push("/school")}>
                <NavLink
                  exact
                  to="/school"
                  className={styles.dropNavNormal}
                  activeClassName={styles.activeClass}
                >
                  For Schools
                </NavLink>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link onClick={() => history.push("/about")}>
              <NavLink
                exact
                to="/about"
                className={styles.navNormal}
                activeClassName={styles.activeClass}
              >
                About Us
              </NavLink>
            </Nav.Link>
            <NavDropdown
              title="Community"
              id="collapsible-nav-dropdown"
              className={styles.navNormal}
            >
              <NavDropdown.Item onClick={() => history.push("/clubs")}>
                <NavLink
                  exact
                  to="/clubs"
                  className={styles.dropNavNormal}
                  activeClassName={styles.activeClass}
                >
                  Clubs
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => history.push("/personaldevelopment")}
              >
                <NavLink
                  exact
                  to="/personaldevelopment"
                  className={styles.dropNavNormal}
                  activeClassName={styles.activeClass}
                >
                  Personal development webinars
                </NavLink>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link onClick={() => history.push("/contact")}>
              <NavLink
                to="contact"
                className={styles.navNormal}
                activeClassName={styles.activeClass}
              >
                Contact Us
              </NavLink>
            </Nav.Link>
            <Nav.Link eventKey={2} onClick={() => history.push("/tutors")}>
              <NavLink
                exact
                to="tutors"
                className={styles.navNormal}
                activeClassName={styles.activeClass}
              >
                Our Tutors
              </NavLink>
            </Nav.Link>
            <Nav.Link
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                background: "#FFFFFF",
                borderRadius: "8px",
                padding: "8px",
                width: "fit-content",
                marginLeft: "20px",
              }}
              onClick={() => history.push("/request-tutor")}
            >
              <div
                style={{
                  fontSize: "18px",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "22px",
                  textAlign: "center",
                  letterSpacing: "0.04em",
                  color: "#1A2578",
                }}
              >
                <NavLink
                  exact
                  to="request-tutor"
                  className={styles.requestTutors}
                  activeClassName={styles.activeClass}
                >
                  Request Tutors
                </NavLink>
              </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
