import React from "react";
import styles from "./Footer.module.scss";
import { Row, Container, Col } from "react-bootstrap";
import { FooterNav } from "../../utils/footerNav";
import Twitter from "../../assets/images/fa-twitter.svg";
import Facebook from "../../assets/images/fa-facebook-square.svg";
import Google from "../../assets/images/fa-google-plus.svg";
import Logo from "../../assets/images/logo1.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div style={{ backgroundColor: "#1A2578" }}>
      <Container>
        <Row
          style={{
            paddingBottom: "30px",
            paddingTop: "40px",
          }}
        >
          <Col lg={true} style={{ borderRight: "2px solid #FFFFFF " }}>
            <div className={styles.footerTop}>
              <div className={styles.novatutition}>
                <img
                  src={Logo}
                  width="34"
                  height="34"
                  className="d-inline-block align-top"
                  alt="Novatuition logo"
                />
                NovaTuition
              </div>
              <div className={styles.footerDescription}>
                Novatuiton is a teaching service that provides opportunities to
                students from different parts of the world to scale and be the
                best in their field.
              </div>
            </div>
            <Col lg={true} className={styles.footerNav}>
              {FooterNav.map((item) => (
                <div className={styles.navItem}>
                  <Link
                    to={item.route}
                    style={{ color: "#ffffff", textDecoration: "none" }}
                  >
                    {item.title}
                  </Link>
                </div>
              ))}
            </Col>
          </Col>
          <Col lg={true}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
                height: "100%",
                width: "100%",
              }}
            >
              <div className={styles.privacy}>
                <div className={styles.policy}>Privacy Policy</div>
                <Link
                  to="/termsandconditions"
                  style={{ textDecoration: "none" }}
                >
                  <div className={styles.terms}>Terms and Conditions</div>
                </Link>
                <img
                  src={Twitter}
                  style={{ paddingLeft: "10px" }}
                  alt="twitter"
                />
                <Link
                  to={{
                    pathname: "https://www.facebook.com/NovaTuitionUK",
                  }}
                  target="_blank"
                >
                  <img
                    src={Facebook}
                    style={{ paddingLeft: "10px" }}
                    alt="facebook"
                  />
                </Link>
                <img
                  src={Google}
                  style={{ paddingLeft: "10px" }}
                  alt="google"
                />
              </div>
              <div className={styles.copyright}>
                © 2022 Novatuition. All rights reserved.
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
