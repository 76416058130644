import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import styles from "./Aboutchild.module.scss";
import IncrementIcon from "../../../assets/images/increment.svg";
import DecrementIcon from "../../../assets/images/decrement.svg";
import PricingEnquiry from "../../../components/PricingEnquiry/PricingEnquiry";

const AboutChild = () => {
  const [increment, setIncrement] = useState(0);

  const dec = () => {
    if (increment <= 0) {
      return;
    } else {
      setIncrement(increment - 1);
    }
  };

  return (
    <div>
      <Container>
        <Row>
          <Col lg={true}>
            <div>
              <div className={styles.tellUs}>Tell us about your child</div>
              <div className={styles.choosing}>
                This helps us in choosing the best tutor for your child.
              </div>
              <div className={styles.count}>
                <div className={styles.kidsCount}>
                  How many kids need tutoring?
                </div>
                <div className={styles.increment}>
                  <div onClick={() => setIncrement(increment + 1)}>
                    <img src={IncrementIcon} alt="increment" />
                  </div>
                  <div className={styles.increase}>{increment}</div>
                  <div onClick={dec}>
                    <img src={DecrementIcon} alt="decrement" />
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.childClass}> Class of child</div>
                <div>
                  <Form.Select
                    size="sm"
                    style={{ width: "70%", padding: "10px" }}
                  >
                    <option value="Select Class">Select Class</option>
                    <option value="KS2">KS2</option>
                    <option value="KS3">KS3</option>
                    <option value="GSCE">GCSE</option>
                    <option value="A Levels">A Levels</option>
                  </Form.Select>
                </div>
              </div>
              <div>
                <div className={styles.childClass}>
                  {" "}
                  What are your goals for this child?
                </div>
                <div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                    style={{ width: "70%" }}
                  >
                    <Form.Control
                      as="textarea"
                      rows={1}
                      placeholder="Enter goals"
                    />
                  </Form.Group>
                </div>
              </div>
              <div>
                <div className={styles.childClass}>Subjects to be taught</div>
                <div className={styles.check}>
                  <Form>
                    {[
                      "Maths",
                      "English",
                      "Computer Science",
                      "History",
                      "Languages",
                      "Psychology",
                    ].map((type) => (
                      <div key={`default-${type}`} className="mb-3">
                        <Form.Check
                          type="checkbox"
                          id={`${type}`}
                          label={`${type}`}
                        />
                      </div>
                    ))}
                  </Form>
                  <Form>
                    {[
                      "Sciences",
                      "Economics",
                      "Business Studies",
                      "Religious Studies",
                      "Music",
                      "Geography",
                    ].map((type) => (
                      <div key={`default-${type}`} className="mb-3">
                        <Form.Check
                          type="checkbox"
                          id={`${type}`}
                          label={`${type}`}
                        />
                      </div>
                    ))}
                  </Form>
                </div>
              </div>
              <div>
                <div className={styles.childClass}>
                  Additional information about your child
                </div>
                <div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                    style={{ width: "80%" }}
                  >
                    <Form.Control as="textarea" rows={5} />
                  </Form.Group>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={true}>
            <PricingEnquiry />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutChild;
