import React from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import styles from "./Privateservice.module.scss";
import BallImage from "../../assets/images/ball.svg";
import StudentDesk from "../../assets/images/Student_desk-10 2.png";
import { Subjects } from "../../utils/subjects";
import { ReasonNav } from "../../utils/reasonNav";
import Checklist from "../../assets/images/Checklist.svg";
import FormImage from "../../assets/images/grades-17 1 (1).png";
import FormImageOne from "../../assets/images/grades-17 1.png";
import { useForm, ValidationError } from "@formspree/react";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Ribbon from "../../assets/images/Ribbon 1.svg";
import GreenCircle from "../../assets/images/ellipse-green.svg";
import GreenAplus from "../../assets/images/green-a-plus.svg";

const Checks = [
  "Helping students set goals and weekly reports to track students progress.",
  "Discussion with student before and at the end of every lesson to establish what they need, and recap on what they’ve learnt.",
  "Exam practice and guidance.",
  "Tips and tricks to help your child learn faster.",
];

const PrivateService = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [state, handleSubmit] = useForm("xeqnjqow");
  if (state.succeeded) {
    dispatch(
      openToastAndSetContent({
        toastStyles: {
          backgroundColor: "green",
        },
        toastContent: "Your mail was recieved succesfully, Thank you!",
      })
    );
    setTimeout(() => window.location.reload(), 5000);
  }
  return (
    <div>
      <Container>
        <Row style={{ marginTop: "40px", marginBottom: "60px" }}>
          <Col lg={true}>
            <div className={styles.heroImageSection}>
              <img src={StudentDesk} alt="Hero" className={styles.heroImage} />
            </div>
          </Col>
          <Col lg={true}>
            {" "}
            <div className={styles.heroSection}>
              <div className={styles.heroLeft}>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "32px",
                    lineHeight: "39px",
                    letterSpacing: "0.02em",
                    textAlign: "center",
                    color: "#1A2578",
                    marginBottom: "20px",
                  }}
                >
                  How can we help?
                </div>
              </div>
              <div
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "18px",
                  lineHeight: "32px",
                  textAlign: "start",
                  letterSpacing: "0.03em",
                  color: "#1A2578",
                }}
              >
                One-to-one expert online tuition, in over 30 subjects including
                Maths, English and Science for 7+, 11+, GCSE and A-Level. In our
                lessons we also help your child stay organised and achieve their
                goals. Give your child the best chance at succeeding in life!
                We’re helping students catchup rapidly after the pandemic 🎯 ​ ​
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={styles.reasons}>
        <Container>
          <Row>
            <Col>
              <div className={styles.nova}>
                <div className={styles.reasonNova}>All subjects areas</div>
              </div>
            </Col>
          </Row>
          <Row>
            {Subjects.map((subject) => (
              <Col
                xs={12}
                md={3}
                className="mb-4"
                key={subject.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className={styles.reasonsSection}
                  style={{
                    backgroundColor: subject.backgroundColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: subject.subBackgroundColor,
                      width: "100px",
                      height: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "14.1818px",
                    }}
                  >
                    {" "}
                    <img
                      src={subject.img}
                      alt="reason"
                      width="50"
                      height="50"
                    />
                  </div>
                  <div className={styles.reasonTitle}>
                    {subject.description}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <div className={styles.lessons}>
                  Lesson starts from £24/hr. Contact us and hire tutors you can
                  trust
                </div>
              </div>
            </Col>
            <Col xs={12} md={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: " center",
                  alignItems: "center",
                  marginTop: "20px",
                  marginBottom: "30px",
                }}
              >
                <div className={styles.requestButton}>
                  <Link to="/contact">
                    <div
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "18px",
                        lineHeight: "22px",

                        textAlign: "center",
                        letterSpacing: "0.04em",

                        color: "#FFFFFF",
                      }}
                    >
                      Request a tutor
                    </div>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.choose}>
        <Container>
          <div className={styles.greenPlus}>
            <img src={GreenAplus} />
          </div>
          <Row style={{ paddingTop: "30px" }}>
            <Col>
              <div className={styles.nova}>
                <div className={styles.chooseNova}>Why choose us?</div>
              </div>
            </Col>
          </Row>
          <Row>
            {ReasonNav.map((reason) => (
              <Col xs={12} md={4} className="mb-4" key={reason.id}>
                <div className={styles.chooseSection}>
                  <div>
                    {" "}
                    <img src={reason.img} alt="reason" />
                  </div>
                  <div className={styles.chooseTitle}>{reason.title}</div>
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "28px",
                      color: "#FFFFFF",
                      mixBlendMode: "normal",
                    }}
                  >
                    {reason.description}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <div className={styles.greenCircle}>
            <img src={GreenCircle} alt="green" />
          </div>
        </Container>
      </div>
      <div style={{ paddingBottom: "30px", background: "#F6F7FF" }}>
        <Container>
          <Row>
            <Col lg={true}>
              {" "}
              <div className={styles.heroSection}>
                <div className={styles.heroLeft}>
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "32px",
                      lineHeight: "52px",
                      letterSpacing: "0.02em",
                      textAlign: "start",
                      color: "#1A2578",
                      marginBottom: "20px",
                    }}
                  >
                    From NovaTuition, you can expect:
                  </div>
                </div>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "18px",
                    lineHeight: "32px",
                    textAlign: "start",
                    letterSpacing: "0.03em",
                    color: "#1A2578",
                  }}
                >
                  {Checks.map((check) => (
                    <div className={styles.checklist}>
                      <div>
                        <img src={Checklist} alt="checks" />
                      </div>
                      <div className={styles.checkTitle}>{check}</div>
                    </div>
                  ))}{" "}
                  ​
                </div>
              </div>
            </Col>
            <Col
              lg={true}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div className={styles.heroImageSection}>
                <img src={FormImage} alt="Hero" className={styles.heroImage} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ background: "#FFFFFF" }}>
        <Container>
          <Row style={{ paddingBottom: "30px", paddingTop: "50px" }}>
            <Col
              lg={true}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div className={styles.heroImageSection}>
                <img
                  src={FormImageOne}
                  alt="Hero"
                  className={styles.heroImage}
                />
              </div>
            </Col>
            <Col lg={true}>
              {" "}
              <div className={styles.formSection}>
                <div className={styles.ribbon}>
                  <img src={Ribbon} alt="ribbon" />
                </div>
                <div className={styles.formBook} style={{ width: "100%" }}>
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "24px",
                      lineHeight: "29px",
                      letterSpacing: "0.02em",
                      color: "#1A2578",
                    }}
                  >
                    Book a quick call with our tutors
                  </div>
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "16px",
                      lineHeight: "19px",
                      letterSpacing: "0.02em",
                      marginTop: "6px",
                      color: "#1A2578",
                    }}
                  >
                    Discuss your wants with us.
                  </div>
                </div>
                <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "17px",
                        /* identical to box height */
                        letterSpacing: "0.05em",
                        textTransform: "uppercase",

                        /* Primary Color */
                        color: "#1A2578",
                        marginTop: "20px",
                      }}
                    >
                      Full Name
                    </Form.Label>
                    <Form.Control
                      id="name"
                      type="name"
                      name="name"
                      placeholder="Enter Full Name"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "17px",
                        /* identical to box height */
                        letterSpacing: "0.05em",
                        textTransform: "uppercase",

                        /* Primary Color */
                        color: "#1A2578",
                      }}
                    >
                      Email{" "}
                    </Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "17px",
                        /* identical to box height */
                        letterSpacing: "0.05em",
                        textTransform: "uppercase",

                        /* Primary Color */
                        color: "#1A2578",
                      }}
                    >
                      Message
                    </Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PrivateService;
