import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import styles from "./CauroselSlider.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as FrontArrow } from "../../assets/images/front-arrow.svg";
import { ReactComponent as BackArrow } from "../../assets/images/back-arrow.svg";
import { ReactComponent as RightQuote } from "../../assets/images/right-quote.svg";
import { ReactComponent as LeftQuote } from "../../assets/images/left-quote.svg";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "They love us, You will too",
    content:
      "My son has been tutored for Maths GCSE for three weeks now and he is enjoying his sessions. My tutor has a very positive, patient and friendly approach. While preparing my son for GCSE providing structured lessons to access his individual needs in his areas of weakness while consistently working towards achieving the best results. I would highly recommend Nova Tuition”.",
    speaker: "Tracey, Parent from Brookmans Park ",
  },
  {
    label: "They love us, You will too",
    content:
      "Hiyam is good for me, she explains stuff calmly and simply for me to understand and remember for later, which helps me to have a better understanding in Year 9. She teaches me more broadly than even our Science teacher at School does. She is making me challenge myself more than I used to, I'll say I don't understand that and she'll explain it in an ordered format . Hiyam is definitely a good tutor and I get on well with her!",
    speaker: "Hannah - Year 8 student St Albans",
  },
  {
    label: "They love us, You will too",
    content:
      "Ru is an amazing tutor and he explains the content very well so that is it easy for me to understand. Thanks for motivating me, you make physics fun!",
    speaker: "Adjoa - Year 9 student St Albans ",
  },
  {
    label: "They love us, You will too",
    content:
      "Our tutor is great; she is helping my daughter with her chemistry GCSE. She is very patient and great at explaining the subject in a clear and attainable way. My daughter feels so much more confident in her learning.",
    speaker: "Andrea - Parent from Welwyn",
  },
  {
    label: "They love us, You will too",
    content:
      "Ama sets a regular timetable for me, she helps me schedule my time and feel good about learning! I no longer see learning as a chore but as an exciting challenge I want to conquer!",
    speaker: "Adjoa - Year 9 student St Albans",
  },
];

const CauroselSlider = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const useStyles = makeStyles({
    root: {
      backgroundColor: "none",
      color: "#fff",
    },
    check: {
      padding: "0px",
    },
  });
  const classes = useStyles();

  const [isDesktop, setDesktop] = React.useState(window.innerWidth > 900);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 900);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "40px",
        marginBottom: "40px",
      }}
    >
      {isDesktop ? (
        <>
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
            style={{ fontSize: "20px", fontWeight: 600, marginRight: "30px" }}
          >
            <div className={styles.frontArrow}>
              {theme.direction === "rtl" ? <FrontArrow /> : <BackArrow />}
            </div>
          </Button>

          <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
            <div className={styles.typography}>{images[activeStep].label}</div>

            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              autoplay={false}
            >
              {images.map((step, index) => (
                <>
                  <div
                    key={step.label}
                    style={{
                      background: "#F3F4FF",
                      borderRadius: "16px",
                      width: "100%",
                      height: "420px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "32px",
                    }}
                  >
                    {Math.abs(activeStep - index) <= 2 ? (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                          }}
                        >
                          <div className={styles.stepContent}>
                            <span className={styles.rightQuote}>"</span>
                            {step.content}
                            <span className={styles.leftQuote}>"</span>
                            <div className={styles.yellowBorder} />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.speaker}>{step.speaker}</div>
                </>
              ))}
            </AutoPlaySwipeableViews>
          </Box>
          <Button
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            classes={{ root: classes.root }}
            style={{
              fontSize: "20px",
              color: "#1976d2",
              fontWeight: 600,
              marginLeft: "30px",
            }}
          >
            <div className={styles.frontArrow}>
              {theme.direction === "rtl" ? <BackArrow /> : <FrontArrow />}
            </div>
          </Button>
        </>
      ) : (
        <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
          <div className={styles.typography}>{images[activeStep].label}</div>

          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            autoplay={false}
          >
            {images.map((step, index) => (
              <>
                <div
                  key={step.label}
                  style={{
                    background: "#F3F4FF",
                    borderRadius: "16px",
                    width: "100%",
                    height: "420px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "32px",
                  }}
                >
                  {Math.abs(activeStep - index) <= 2 ? (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <div className={styles.stepContent}>
                          <span className={styles.rightQuote}>"</span>
                          {step.content}
                          <span className={styles.leftQuote}>"</span>
                          <div className={styles.yellowBorder} />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={styles.speaker}>{step.speaker}</div>
              </>
            ))}
          </AutoPlaySwipeableViews>
        </Box>
      )}
    </div>
  );
};

export default CauroselSlider;
