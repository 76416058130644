import React from "react";
import { Row, Container, Col, Form, Button } from "react-bootstrap";
import styles from "./Contact.module.scss";
import Phone from "../../assets/images/fa-phone.svg";
import Mail from "../../assets/images/fa-envelope.svg";
import FaceBook from "../../assets/images/fa-envelope-2.svg";
import LinkedIn from "../../assets/images/fa-envelope-3.svg";
import Twitter from "../../assets/images/fa-envelope-4.svg";
import { useForm, ValidationError } from "@formspree/react";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Ribbon from "../../assets/images/Ribbon 1.svg";

const Contact = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [state, handleSubmit] = useForm("xeqnjqow");
  if (state.succeeded) {
    dispatch(
      openToastAndSetContent({
        toastStyles: {
          backgroundColor: "green",
        },
        toastContent: "Your mail was recieved succesfully, Thank you!",
      })
    );
    setTimeout(() => window.location.reload(), 5000);
  }
  return (
    <div style={{ backgroundColor: "rgba(196, 202, 255, 0.15)" }}>
      <Container>
        <Row style={{ paddingBottom: "30px" }}>
          <Col lg={true}>
            <div className={styles.heroImageSection}>
              <div>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "32px",
                    lineHeight: "40px",
                    /* identical to box height, or 125% */
                    letterSpacing: "-0.4px",

                    /* Primary Color */
                    color: "#1A2578",
                  }}
                >
                  How can we help your child?
                </div>
                <div className={styles.contactUs}>
                  Contact us to get answers to all your questions.
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  marginTop: "40px",
                }}
              >
                <img src={Phone} alt="phone" />
                <div className={styles.number}>07580052870</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  marginTop: "40px",
                  paddingBottom: "40px",
                  borderBottom: "1px solid #1A2578",
                }}
              >
                <img src={Mail} alt="mail" />
                <div className={styles.mail}>contact@novatuition.co.uk</div>
              </div>
              <div
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "22px",
                  lineHeight: "32px",
                  color: "#1A2578",
                }}
              >
                Keep up with us on social media
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <img src={FaceBook} alt="facebook" />
                  <Link
                    to={{
                      pathname: "https://www.facebook.com/NovaTuitionUK",
                    }}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "26px",
                        color: "#1A2578",
                        paddingLeft: "10px",
                      }}
                    >
                      Facebook
                    </div>
                  </Link>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <img src={LinkedIn} alt="linkedIn" />
                  <Link
                    to={{
                      pathname:
                        "https://www.linkedin.com/company/43295917/admin/",
                    }}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "26px",
                        color: "#1A2578",
                        paddingLeft: "10px",
                      }}
                    >
                      LinkedIn
                    </div>
                  </Link>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <img src={Twitter} alt="twitter" />
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "16px",
                      lineHeight: "26px",
                      color: "#1A2578",
                      paddingLeft: "10px",
                    }}
                  >
                    Twitter
                  </div>
                </div> */}
              </div>
            </div>
          </Col>
          <Col lg={true}>
            {" "}
            <div className={styles.formSection}>
              <div className={styles.ribbon}>
                <img src={Ribbon} alt="ribbon" />
              </div>
              <div className={styles.formBook} style={{ width: "100%" }}>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "29px",
                    letterSpacing: "0.02em",
                    color: "#1A2578",
                  }}
                >
                  Book a quick call with our tutors
                </div>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "16px",
                    lineHeight: "19px",
                    letterSpacing: "0.02em",
                    marginTop: "6px",
                    color: "#1A2578",
                  }}
                >
                  Discuss your wants with us.
                </div>
              </div>
              <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "17px",
                      /* identical to box height */
                      letterSpacing: "0.05em",
                      textTransform: "uppercase",

                      /* Primary Color */
                      color: "#1A2578",
                      marginTop: "20px",
                    }}
                  >
                    Full Name
                  </Form.Label>
                  <Form.Control
                    id="name"
                    type="name"
                    name="name"
                    placeholder="Enter Full Name"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "17px",
                      /* identical to box height */
                      letterSpacing: "0.05em",
                      textTransform: "uppercase",

                      /* Primary Color */
                      color: "#1A2578",
                    }}
                  >
                    Email{" "}
                  </Form.Label>
                  <Form.Control
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Enter email"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "17px",
                      /* identical to box height */
                      letterSpacing: "0.05em",
                      textTransform: "uppercase",

                      /* Primary Color */
                      color: "#1A2578",
                    }}
                  >
                    Message
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    id="textarea"
                    type="area"
                    name="area"
                  />
                </Form.Group>

                <Button variant="primary" type="submit">
                  Send Message
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
