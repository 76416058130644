import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavBar from "../components/navbar/NavBar";
import HeroSection from "../views/herosection/HeroSection";
import About from "../views/about/About";
import PrivateService from "../views/privateservice/PrivateService";
import School from "../views/school/School";
import Contact from "../views/contact/Contact";
import Footer from "../components/footer/Footer";
import Tutors from "../views/tutors/Tutors";
import Clubs from "../views/clubs/Clubs";
import TutorDescriptions from "../views/tutorDescription/TutorDescriptions";
import PersonalDev from "../views/personalDev/PersonalDev";
import RequestStepper from "../views/requestStepper/RequestStepper/RequestStepper";
import { Container } from "react-bootstrap";
import TermsCondition from "../views/termsConditions/TermsCondition";

export default function AppRoutes() {
  return (
    <Router>
      <NavBar />
      <Switch>
        <Route exact path="/">
          <HeroSection />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/private">
          <PrivateService />
        </Route>
        <Route exact path="/school">
          <School />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/tutors">
          <Tutors />
        </Route>
        <Route exact path="/request-tutor">
          <RequestStepper />
        </Route>
        <Route exact path="/description/:id">
          <TutorDescriptions />
        </Route>
        <Route exact path="/clubs">
          <Clubs />
        </Route>
        <Route exact path="/personaldevelopment">
          <PersonalDev />
        </Route>
        <Route exact path="/termsandconditions">
          <TermsCondition />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}
