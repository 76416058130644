import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import styles from "./Personaldev.module.scss";
import Children from "../../assets/images/Group 2800.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as Slider } from "react-responsive-carousel";
import { Carousel } from "@trendyol-js/react-carousel";
import WebinarOne from "../../assets/images/image 1.svg";
import { WebinarContent } from "../../utils/webinar";
import Calendar from "../../assets/images/date.svg";
import BackArrow from "../../assets/images/webinar-back.svg";
import FrontArrow from "../../assets/images/webinar-front.svg";

const PersonalDev = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 900);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 900);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div>
      <div style={{ background: "#F6F7FF" }}>
        <Container>
          <Row>
            <Col lg={true}>
              <div className={styles.personalDev}>Personal Development</div>
              <div className={styles.personalDescription}>
                <div>
                  <div className={styles.firstParagraph}>
                    At NovaTuition, we believe that personal development is key!
                    We are of the opinion that whilst academia is so important,
                    having confidence and discipline makes learning worthwhile.
                  </div>
                  <div className={styles.secondParagraph}>
                    Making sure students recognise their value, intelligence and
                    talent is a crucial part of the work we do. That is why we
                    encourage goal setting, joining our workshops and
                    interacting with other NovaTuition students because we
                    believe that Personal Development is a journey, not a
                    destination.
                  </div>
                  <div className={styles.thirdParagraph}>
                    Since the beginning, we created free workshops to help
                    students reach their fullest potential and provide them with
                    extra support outside of school.
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={styles.carouselItems}>
        <div className={styles.pastEvents}>Past Events</div>
        {isDesktop ? (
          <Carousel
            show={3.5}
            slide={1}
            swiping={true}
            responsive={true}
            useArrowKeys={true}
            rightArrow={
              <div>
                <img src={FrontArrow} alt="front-arrow" />
              </div>
            }
            leftArrow={
              <div>
                <img src={BackArrow} alt="back-arrow" />
              </div>
            }
          >
            {WebinarContent.map((content) => (
              <div>
                <img src={content.image} alt={content.description}></img>
                <div className={styles.description}>{content.description}</div>
                <div className={styles.dateSection}>
                  <img src={Calendar} alt="calender" />
                  <div className={styles.date}>{content.date}</div>
                </div>
              </div>
            ))}
          </Carousel>
        ) : (
          <Slider>
            {WebinarContent.map((content) => (
              <div>
                <img
                  src={content.image}
                  alt={content.description}
                  height="250"
                  width="200"
                ></img>
                <div className={styles.description}>{content.description}</div>
                <div className={styles.dateSection}>
                  <img src={Calendar} alt="calender" height="17" width="19" />
                  <div className={styles.dateSmallScreen}>{content.date}</div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
      <div style={{ width: "100%", marginTop: "40px" }}>
        <img src={Children} style={{ width: "100%" }} alt="children-playing" />
      </div>
    </div>
  );
};

export default PersonalDev;
