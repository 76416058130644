import React from "react";
import { Container, Row, Form, Col, Button } from "react-bootstrap";
import PricingEnquiry from "../../../components/PricingEnquiry/PricingEnquiry";
import MuiPhoneNumber from "material-ui-phone-number";
import styles from "./Confirmation.module.scss";

const Confirmation = () => {
  return (
    <div>
      {" "}
      <Container>
        <Row>
          <Col lg={true}>
            <div>
              <div className={styles.tellUs}>Summary</div>
              <div className={styles.choosing}>
                Please confirm the information and submit.
              </div>

              <div>
                <div className={styles.childClass}>Number of kids</div>
                <div className={styles.check}>1</div>
              </div>
              <div>
                <div className={styles.check}>Subjects</div>
                <div className={styles.childClass}>
                  Maths, English, Verbal Reasoning & Quantitative Reasoning
                </div>
              </div>
              <div>
                <div className={styles.check}>Class</div>
                <div className={styles.childClass}>KS3</div>
              </div>
            </div>
          </Col>
          <Col lg={true}>
            <PricingEnquiry />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Confirmation;
