import React from "react";
import styles from "./Pricingenquiry.module.scss";

const PricingEnquiry = () => {
  return (
    <div className={styles.paymentPlans}>
      <div>
        <div className={styles.priceList}>Price List and Payment Plan</div>
        <div className={styles.list}>
          <li>KS2 - £24/hr</li>
          <li>KS3 - £26/hr</li>
          <li>GCSE - 28/hr</li>
          <li>ALevel - 32/hr</li>
        </div>
        <div className={styles.optIn}>
          You can opt to pay in full or per hour{" "}
        </div>
        <div className={styles.enquiries}>
          For other enquiries, you can contact us at
          <a
            href="mailto:contact@novatuition.co.uk"
            style={{ paddingLeft: "2px" }}
          >
            contact@novatuition.co.uk
          </a>
        </div>
      </div>
    </div>
  );
};

export default PricingEnquiry;
