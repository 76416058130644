import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import styles from "./TutorDescription.module.scss";
import axios from "axios";
import { TutorDetails } from "../../utils/tutors";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as BackArrow } from "../../assets/images/back-arrow.svg";

// `../mock/tutors.json/?id=${id}`
const TutorDescriptions = () => {
  const [description, setDescription] = useState<any[]>([]);
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    axios.get("../mock/tutors.json").then((res: any) => {
      console.log("res", res.data);
      setDescription(res.data);
    });
  }, []);

  const newDescription = description.filter((x: any) => x.id === id);

  const history = useHistory();

  return (
    <div style={{ background: "#F6F7FF" }}>
      <Container>
        <Row>
          {newDescription.map((describe) => (
            <div>
              <div onClick={() => history.goBack()} className={styles.goBack}>
                <BackArrow />
              </div>
              <div className={styles.aboutContent}>
                <img
                  src={describe.image}
                  alt="sasha"
                  width="100"
                  height="100"
                  style={{
                    border: "2px solid #1A2578",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
                <div className={styles.aboutName}>
                  <div className={styles.name}>{describe.name}</div>
                  <div className={styles.undergraduate}>{describe.course}</div>
                  <div className={styles.university}>{describe.University}</div>
                </div>
              </div>
              <div>
                {describe?.Teaching_Experience ? (
                  <>
                    <div className={styles.gcse}>Teaching Experience</div>
                    <div className={styles.subjects}>
                      {describe?.Teaching_Experience}
                    </div>
                  </>
                ) : null}
                {describe.about ? (
                  <>
                    <div className={styles.about}>About {describe.name}</div>
                    <div className={styles.aboutDescription}>
                      {describe.about}​
                    </div>
                  </>
                ) : null}
                {describe?.fun_fact ? (
                  <>
                    <div className={styles.gcse}>Fun Facts</div>
                    <div className={styles.subjects}>{describe?.fun_fact}</div>
                  </>
                ) : null}
                {describe?.Qualifications?.gsce ||
                describe?.Qualifications?.a_level ? (
                  <>
                    <div className={styles.qualifications}>
                      <div className={styles.certifications}>
                        Qualifications
                      </div>
                      {describe?.Qualifications?.gsce ? (
                        <>
                          <div className={styles.gcse}>GCSE</div>
                          <div className={styles.subjects}>
                            {describe?.Qualifications?.gsce}
                          </div>{" "}
                        </>
                      ) : null}
                      {describe?.Qualifications?.a_level ? (
                        <>
                          <div className={styles.gcse}>A-Level</div>
                          <div className={styles.subjects}>
                            {describe?.Qualifications?.a_level}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default TutorDescriptions;
