import React, { useState, useEffect } from "react";
import styles from "./Tutors.module.scss";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { TutorDetails } from "../../utils/tutors";
import { useDispatch } from "react-redux";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";

const Tutors = () => {
  const [tutors, setTutors] = useState<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(openLoader());
    axios.get("./mock/tutors.json").then((res: any) => {
      dispatch(closeLoader());
      console.log("res", res.data);
      setTutors(res.data);
    });
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div className={styles.hello}>NovaTuition Team</div>
            <div className={styles.description}>
              NovaTuition selects kind, dedicated and engaging tutors with
              experience in making lessons effective and fun for students. Our
              tutors are from the UK's top universities and have a passion for
              teaching and a strong desire to assist students' in reaching their
              academic goals. Click on our tutors Bios to find out more!
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          {tutors?.map((tutor: any) => {
            return (
              <Col xs={12} md={4} className="mb-4" key={tutor.id}>
                <div className={styles.tutors}>
                  <div className={styles.tutorDetails}>
                    <img
                      src={tutor.image}
                      width="80"
                      height="80"
                      alt="tutors"
                      style={{ borderRadius: "100%", objectFit: "cover" }}
                    />
                    <div className={styles.tutorName}>{tutor.name}</div>
                    <div className={styles.course}>{tutor.course}</div>
                    <div className={styles.university}>{tutor.University}</div>
                    <Link to={`description/${tutor.id}`}>Read More</Link>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Tutors;
