import { combineReducers } from "redux";
import auth from "./auth/index";
import toast from "./toast/index";
import modal from "./modal/index";
import loader from "./loader/index";

const rootReducer = combineReducers({
  auth,
  toast,
  modal,
  loader,
});

export default rootReducer;
export type AppState = ReturnType<typeof rootReducer>;
