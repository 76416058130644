import React, { useState } from "react";
import { Steps, Button, message } from "antd";
import styles from "./Requeststepper.module.scss";
import ContactInfo from "../ContactInfo/ContactInfo";
import AboutChild from "../AboutChild/AboutChild";
import LessonSchedule from "../LessonSchedule/LessonSchedule";
import Confirmation from "../Confirmation/Confirmation";
import "antd/dist/antd.css";
import { Container, Button as SubmitButton } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useForm, ValidationError } from "@formspree/react";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import { useHistory } from "react-router";

const { Step } = Steps;

const steps = [
  {
    title: "About your Child",
    content: <AboutChild />,
  },
  {
    title: "Contact Information",
    content: <ContactInfo />,
  },
  {
    title: "Lesson Schedules",
    content: <LessonSchedule />,
  },
  {
    title: "Summary",
    content: <Confirmation />,
  },
];

const RequestStepper = () => {
  const dispatch = useDispatch();

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const { push } = useHistory();
  const [state, handleSubmit] = useForm("xeqnjqow");
  if (state.succeeded) {
    dispatch(
      openToastAndSetContent({
        toastStyles: {
          backgroundColor: "green",
        },
        toastContent: "Your mail was recieved succesfully, Thank you!",
      })
    );
    setTimeout(() => window.location.reload(), 5000);
  }

  return (
    <Container style={{ marginTop: "30px" }}>
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <Steps current={current} style={{ marginBottom: "20px" }}>
          {steps.map((item) => (
            <Step
              key={item.title}
              title={item.title}
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "24px",
                letterSpacing: "0.02em",

                /* Primary Color */

                color: "#1A2578",
              }}
            />
          ))}
        </Steps>
        <div className={styles.steps_content}>{steps[current].content}</div>
        <div className={styles.steps_action}>
          <div
            style={{ display: "flex", marginTop: "30px", marginBottom: "30px" }}
          >
            {current < steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => next()}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "16px",
                  background: "#1A2578",
                  borderRadius: "8px",
                }}
              >
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => message.success("Mail submitted successfully!")}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "16px",
                  background: "#1A2578",
                  borderRadius: "8px",
                }}
              >
                Submit
              </Button>
            )}
            {current > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                Previous
              </Button>
            )}
          </div>
        </div>
      </form>
    </Container>
  );
};

export default RequestStepper;
