import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import styles from "./terms.module.scss";

const TermsCondition = () => {
  return (
    <div>
      <Container>
        <div className={styles.terms}>Terms and Conditions</div>
        <div className={styles.services}>
          Upon booking and paying for NovaTuition services, you agree to these
          Terms and Conditions. These Terms & Conditions apply to all tuition,
          webinars, products and other services offered by NovaTuition.
        </div>
        <div className={styles.terminology}>Terminology Explained</div>
        <div className={styles.services}>
          <span className={styles.leader}>Tutor/Education Leader</span> - This
          includes members of staff, including tutors of NovaTuition.
        </div>
        <div className={styles.services}>
          <span className={styles.leader}>You/Your</span>- The client, persons
          responsible for the payments of products and services and/or
          guardianship of the tutee.
        </div>
        <div className={styles.services}>
          <span className={styles.leader}>Services and Products </span> - Any
          service or products delivered by NovaTuition, including however not
          limited to, tuition lessons, tuition services, business training and
          course/resources hosted on www.novatuition.co.uk
        </div>
        <div className={styles.services}>
          <span className={styles.leader}>Platform</span>- any services and
          products delivered or administered through www.novatuition.co.uk or
          any other applications and software used for tuition and related
          services and products.
        </div>
        <div className={styles.terminology}>Bookings</div>
        <div className={styles.services}>
          All private tuition or business training bookings and arrangements
          must be confirmed by You and the Tutor/Trainer via WhatsApp group
          chat. Telephone written messages will be regarded as formal
          confirmation. All bookings and enrolments are confirmed once payment
          has been received and completed.
        </div>
        <div className={styles.terminology}>Payments</div>
        <div className={styles.services}>
          <div>
            1. All payments must be settled prior to the service date and time.
          </div>{" "}
          2. All payments are processed through GoCardless.
        </div>
        <div className={styles.terminology}>Suspension of Services</div>
        <div className={styles.services}>
          <div>
            This agreement may be ended by written notice at any time by You or
            the Tutor/Educational Leader. Situations that may lead to the
            suspension of services with immediate effect: Payment has not been
            received prior to the arranged lesson/s
            <div>
              • Gross misconduct e.g. threatening behaviour from the pupil or
              anyone associated with the pupil. If this occurs during a lesson,
              no refund of monies paid will be issued.
            </div>
            <div>
              • There are excessive cancellations or rearrangement of services
              (this will be at NovaTuition’s Management Team’s discretion){" "}
            </div>
            <div>• Any breach of these Terms and Conditions </div>
            <div>• Force majeure </div>
            <div>
              • Attempts to make lesson arrangements outside of NovaTuition with
              Tutors/Students{" "}
            </div>
            <div>
              • Note, if breaks are taken for any reason, it will be at
              NovaTuition’s discretion whether your lesson slot can be reserved.
            </div>
          </div>
        </div>

        <div className={styles.terminology}>Cancellations</div>
        <div className={styles.services}>
          <div>
            If a private tuition lesson with NovaTuition’s tutors is cancelled,
            please provide at least 24 hours’ notice. Failure to do so will
            result in the lesson payment being owed.
          </div>
          <div>
            Flexibility will be exercised wherever possible and within reason.
            If last-minute cancellations, with less than 24 hours’ notice occur,
            it will be at NovaTuition’s discretion whether to waive the lesson
            payment or not.{" "}
          </div>
          <div>
            For classes, services or resources paid via GoCardless, all monies
            paid are non-refundable and nontransferable unless the Tutor cancels
            the service.{" "}
          </div>
          <div>
            For services outside of Private Tuition, please refer to the
            cancellation policy given.
          </div>
        </div>
        <div className={styles.terminology}>Your Obligations</div>
        <div className={styles.services}>
          <div>You agree to comply fully with these Terms and Conditions.</div>
          <div>
            You are responsible for the prompt attendance of the pupil at the
            tutoring sessions.
          </div>
          <div>
            If the pupil is late, NovaTuition tutors, cannot guarantee to extend
            the time of the tutoring session.
          </div>
          <div>
            If your or your tutor is late due to technical problems, every
            effort will be made to make up for the lost time.
          </div>
        </div>
        <div className={styles.terminology}>Online Session Rules</div>
        <div className={styles.services}>
          <div>
            To ensure safeguarding measures are in place, the Tutor and pupil
            agree to the following:
          </div>
          <div>• Webcams must be always on for the duration of the lesson</div>
          <div>• Virtual backgrounds must not be used</div>
          <div>
            • Only the pupil who has paid for the lesson may attend In the case
            of an emergency, the contact details You have supplied will be used.
            Please ensure that you inform us of any changes by emailing
            contact@novatuition.co.uk.
          </div>
        </div>

        <div className={styles.terminology}>Account Closure</div>
        <div className={styles.services}>
          <div>• fail to reply to messages</div>
          <div>• fail to attend arranged Lessons</div>
          <div>• refuse to make Lesson Payments when requested</div>
          <div>• repeatedly cause billing disputes</div>
          <div>• send an excessive number of messages</div>
          <div>• do not log in to your Account for more than 4 weeks</div>
          <div>• breach the Agreement.</div>
        </div>

        <div className={styles.terminology}>Marketing and Your Data</div>
        <div className={styles.services}>
          <div>
            • From time to time, snippets of lessons may be recorded for
            marketing purposes. On all occasions, student’s faces, voices and
            identities will be completely concealed unless permission is
            requested from the guardian first.
          </div>
          <div>
            • Testimonials from you will be used for marketing. In all cases,
            student identities will be concealed however first names may be
            used. If you object to this, please let NovaTuition know in writing
            by emailing contact@novatuition.co.uk
          </div>
        </div>

        <div className={styles.services}>
          NovaTuition reserves the right to amend these Terms and Conditions at
          any time. Every effort will be made to notify you of changes, but you
          are encouraged to check these terms if you are in any doubt.
        </div>
      </Container>
    </div>
  );
};

export default TermsCondition;
