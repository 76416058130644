import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Carousel,
  Form,
} from "react-bootstrap";
import HeroImage from "../../assets/images/Rectangle 14.png";
import Padlock from "../../assets/images/padlock.svg";
import styles from "./Hero.module.scss";
import Enthusiastic from "../../assets/images/Rectangle 3320.png";
import Online from "../../assets/images/Rectangle 3320 (1).png";
import Personalised from "../../assets/images/Rectangle 3320 (2).png";
import DoubleArrows from "../../assets/images/doublearrows.svg";
import Topology from "../../assets/images/Topology-2.png";
import Marketing from "../../assets/images/marketing.svg";
import MeetingTeam from "../../assets/images/meeting-team.svg";
import Graduation from "../../assets/images/graduation.svg";
import Book from "../../assets/images/book.svg";
import Approved from "../../assets/images/approved.svg";
import Headphones from "../../assets/images/headphones-customer-support-human 1.svg";
// import Martha from "../../assets/images/martha.svg";
// import Zute from "../../assets/images/Zute.svg";
// import Meishu from "../../assets/images/meishu.svg";
// import Sasha from "../../assets/images/sasha.svg";
import Stars from "../../assets/images/stars.svg";
import { useForm, ValidationError } from "@formspree/react";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { useHistory } from "react-router";
import FormImage from "../../assets/images/grades-17 1.png";
import CauroselSlider from "../../components/CauroselSlider/CauroselSlider";
import { Grid } from "@material-ui/core";
import Ribbon from "../../assets/images/Ribbon 1.svg";
import TrustPilot from "../../assets/images/Group 2774.svg";
import { Link } from "react-router-dom";
import TopImage from "../../assets/images/Rectangle 15.svg";
import { ReactComponent as Aplus } from "../../assets/images/a-plus.svg";
import { ReactComponent as Triangle } from "../../assets/images/triangle.svg";
import Ellipse from "../../assets/images/Ellipse 2.svg";
import ByChild from "../../assets/images/by-child.svg";
import SlantArrow from "../../assets/images/slant-arrow.svg";
import PurpleCircle from "../../assets/images/purple-circle.svg";
import Martha from "../../assets/images/Group 2789.svg";
import Zute from "../../assets/images/Group 2781.svg";
import Meishu from "../../assets/images/meishu-one.svg";
import Sasha from "../../assets/images/sasha-one.svg";
import Zetu from "../../assets/images/zetu-one.svg";

const tutoringApproaches = [
  {
    id: 1,
    img: Enthusiastic,
    description: "Enthusiastic and dedicated tutors",
  },
  {
    id: 2,
    img: Online,
    description: "ONLINE LESSONS in 30+ subjects FROM £24/HR",
  },
  {
    id: 3,
    img: Personalised,
    description: "Personalised Study Plan AND GOAL SETTING STRATEGY",
  },
];

const gradeSpecifics = [
  {
    id: 1,
    emoji: "🎯",
    description:
      "Aiming for a specific grade, school,university or career goal?",
  },
  {
    id: 2,
    emoji: "💪🏼 ",
    description:
      "Finding a subject challenging and needs help raising confidence and resilience?",
  },
  {
    id: 3,
    emoji: "📊 ",
    description:
      "Full of potential and would like tuition to take them to the next level?",
  },
];

const reasonNav = [
  {
    id: 1,
    img: Marketing,
    title: "Highly skilled and DEDICATED",
    description:
      "Our tutors are rigorously selected and are some of the best in the country.",
  },
  {
    id: 2,
    img: MeetingTeam,
    title: "GOAL ORIENTATED LESSONS",
    description:
      "Whatever your child wants to achieve, you best believe we’ll help to achieve that.",
  },
  {
    id: 3,
    img: Graduation,
    title: "Chasing the best results",
    description:
      "Our goal is to increase, improve and develop the academic capacity of all our students.",
  },
  {
    id: 4,
    img: Book,
    title: "Passionate about teaching",
    description:
      "Our tutors are eager to make a difference and be an inspiration to students.",
  },
  {
    id: 5,
    img: Approved,
    title: "WEEKLY STUDY PLAN",
    description:
      "Every child gets accesss to our weekly study plan to keep track of progress.",
  },
  {
    id: 6,
    img: Headphones,
    title: "ALL-ROUND SUPPORT",
    description:
      "Our tutors provide both academic and personal development support to students.",
  },
];

const topTutors = [
  {
    id: 1,
    name: "Martha",
    img: Martha,
    course: "English and Literature Undergraduate",
  },
  {
    id: 2,
    name: "Zute",
    img: Zetu,
    course: "Medical Student",
  },
  {
    id: 3,
    name: "Meishu",
    img: Meishu,
    course: "Physics Undergraduate",
  },
  {
    id: 4,
    name: "Sasha",
    img: Sasha,
    course: "English and Literature Undergraduate",
  },
];

const HeroSection = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [state, handleSubmit] = useForm("xeqnjqow");
  if (state.succeeded) {
    dispatch(
      openToastAndSetContent({
        toastStyles: {
          backgroundColor: "green",
        },
        toastContent: "Your mail was recieved succesfully, Thank you!",
      })
    );
    setTimeout(() => window.location.reload(), 5000);
  }
  return (
    <div>
      <Container>
        <Row>
          <Col lg={true}>
            {" "}
            <div className={styles.heroSection}>
              <div className={styles.heroLeft}>
                {/* <img src={Padlock} alt="padlock" /> */}
                <div className={styles.oneOnOne}>
                  Unlock your child’s full potential
                </div>
              </div>
              <div
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "19px",
                  letterSpacing: "0.03em",
                  color: "#1A2578",
                }}
              >
                Discover our online tuition that raises confidence and grades
              </div>
              <div className={styles.findTutors}>
                <Link
                  to="/tutors"
                  style={{ color: "#ffffff", textDecoration: "none" }}
                >
                  Find Tutors
                </Link>
              </div>

              {/* <div className={styles.tabs}>
                <div className={styles.tabContent}>
                  <div style={{ fontFamily: "Inter", fontSize: "14px" }}>
                    What do you want to learn
                  </div>
                </div>
              </div> */}
              <div style={{ paddingTop: "5px" }}>
                <img src={TrustPilot} alt="trust-pilot" />
              </div>
            </div>
          </Col>
          <Col lg={true} style={{ display: "flex", justifyContent: "end" }}>
            <div className={styles.heroImageSection}>
              <img src={HeroImage} alt="Hero" className={styles.heroImage} />
            </div>
          </Col>
        </Row>
      </Container>
      <div className={styles.backgroundTutor}>
        <Container>
          <Row>
            <Col>
              <div className={styles.holistic}>
                <div className={styles.successful}>
                  A holistic and successful approach to online tuition
                </div>
                <div className={styles.flourish}>
                  See your child flourish and succeed with our
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {tutoringApproaches.map((tutoring) => (
              <Col sm={true}>
                <div key={tutoring.id}>
                  <div className={styles.online}>
                    <img
                      src={tutoring.img}
                      alt="tutoring"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className={styles.arrows}>
                    <img src={DoubleArrows} alt="double-arrow" />
                    <div className={styles.description}>
                      {tutoring.description}
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Aplus className={styles.aplus} />
        </Container>
      </div>
      <div className={styles.child}>
        <Container>
          <Row>
            <Col lg={true}>
              <div className={styles.topologyImageSection}>
                <Triangle />
                <img src={TopImage} className={styles.topImage} alt="top" />
                <img
                  src={Topology}
                  alt="topology"
                  className={styles.topologyImage}
                />
              </div>
            </Col>

            <Col lg={true}>
              <div className={styles.childList}>
                <div className={styles.yourChild}>
                  {" "}
                  Is Your Child?{" "}
                  <img
                    src={ByChild}
                    alt="by-child"
                    style={{
                      position: "absolute",
                      right: "19rem",
                      top: "1rem",
                    }}
                  />
                </div>
                <div>
                  {gradeSpecifics.map((grades) => (
                    <div key={grades.id} className={styles.grades}>
                      <div>{grades.emoji}</div>
                      <div className={styles.gradeDescription}>
                        {grades.description}
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <Link to="/private">
                    <div className={styles.subject}>
                      Find out more about the subjects we offer
                    </div>
                  </Link>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    src={Ellipse}
                    alt="ellipse"
                    style={{
                      height: "30px",
                      width: "30px",
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.reasons}>
        <Container>
          <Row>
            <Col>
              <div className={styles.nova}>
                <div className={styles.reasonNova}>Why NovaTuition?</div>
              </div>
            </Col>
          </Row>
          <Row>
            {reasonNav.map((reason) => (
              <Col xs={12} md={4} className="mb-4" key={reason.id}>
                <div className={styles.reasonsSection}>
                  <div>
                    {" "}
                    <img src={reason.img} alt="reason" />
                  </div>
                  <div className={styles.reasonTitle}>{reason.title}</div>
                  <div>{reason.description}</div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className={styles.book}>
        <Container>
          <Row>
            <Col lg={true}>
              {" "}
              <div className={styles.tutorsSection}>
                <div className={styles.tutorsLeft}>
                  <div className={styles.bookTutor}>Book a tutor</div>
                </div>
                <div className={styles.tutorMeeting}>
                  Contact us to book a free 15 minute meeting to discuss your
                  child’s needs and goals
                </div>
                <Link to="/contact" style={{ textDecoration: "none" }}>
                  <div className={styles.tutorStyle}>
                    <div className={styles.tutorText}>Book a tutor</div>
                  </div>
                </Link>
                <div className={styles.slantArrow}>
                  <img src={SlantArrow} alt="slant-arrow" />
                </div>
              </div>
            </Col>
            <Col lg={true} style={{ paddingTop: "50px" }}>
              <div className={styles.purpleCircle}>
                <img src={PurpleCircle} alt="purple-circle" />
              </div>
              <div className={styles.best}>
                <Grid spacing={2} container>
                  {topTutors.map((top) => (
                    <Grid item md={6} xs={12} style={{ paddingBottom: "20px" }}>
                      <div className={styles.topCard}>
                        <div style={{ width: "100%" }}>
                          <img src={top.img} alt="top-tutors" />
                        </div>
                        {/* <div className={styles.topContent}>
                          <div className={styles.topName}>{top.name}</div>
                          <div className={styles.topCourse}>{top.course}</div>
                          <div>
                            <img src={Stars} alt="stars" />
                          </div>
                        </div> */}
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.caurosel}>
        <Container>
          <Row>
            <Col lg={true}>
              {" "}
              <div className={styles.carouselSection}>
                <CauroselSlider />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: "rgba(196, 202, 255, 0.15)" }}>
        <Container>
          <Row style={{ paddingBottom: "30px", paddingTop: "50px" }}>
            <Col
              lg={true}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div className={styles.heroImageSection}>
                <img
                  src={FormImage}
                  alt="Hero"
                  className={styles.heroImage}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                />
              </div>
            </Col>
            <Col lg={true}>
              {" "}
              <div className={styles.formSection}>
                <div className={styles.ribbon}>
                  <img src={Ribbon} />
                </div>
                <div className={styles.formBook} style={{ width: "100%" }}>
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "24px",
                      lineHeight: "29px",
                      letterSpacing: "0.02em",
                      color: "#1A2578",
                    }}
                  >
                    Book a quick call with our tutors
                  </div>
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "16px",
                      lineHeight: "19px",
                      letterSpacing: "0.02em",
                      marginTop: "6px",
                      color: "#1A2578",
                    }}
                  >
                    Discuss your wants with us.
                  </div>
                </div>
                <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "17px",
                        /* identical to box height */
                        letterSpacing: "0.05em",
                        textTransform: "uppercase",

                        /* Primary Color */
                        color: "#1A2578",
                        marginTop: "10px",
                      }}
                    >
                      Full Name
                    </Form.Label>
                    <Form.Control
                      id="name"
                      type="name"
                      name="name"
                      placeholder="Enter full name"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "17px",
                        /* identical to box height */
                        letterSpacing: "0.05em",
                        textTransform: "uppercase",

                        /* Primary Color */
                        color: "#1A2578",
                      }}
                    >
                      Email{" "}
                    </Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "17px",
                        /* identical to box height */
                        letterSpacing: "0.05em",
                        textTransform: "uppercase",

                        /* Primary Color */
                        color: "#1A2578",
                      }}
                    >
                      Message
                    </Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default HeroSection;
