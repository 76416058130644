import React, { useState } from "react";
import styles from "./Lessonschedule.module.scss";
import { Container, Row, Form, Col, Button } from "react-bootstrap";
import PricingEnquiry from "../../../components/PricingEnquiry/PricingEnquiry";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const LessonSchedule = () => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div>
      <Container>
        <Row>
          <Col lg={true}>
            <div>
              <div className={styles.tellUs}>
                When do you want the lessons to hold?
              </div>
              <div className={styles.choosing}>
                Fix a convenient time and date for your lesson sessions.
              </div>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={styles.childClass}>
                    Full Names
                  </Form.Label>
                  <Form.Control type="name" style={{ width: "80%" }} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={styles.childClass}>Email</Form.Label>
                  <Form.Control type="email" style={{ width: "80%" }} />
                </Form.Group>
              </Form>
              <div>
                <div className={styles.childClass}>Subjects to be taught</div>
                <div className={styles.check}>
                  <Form>
                    {["Monday", "Tuesday", " Wednesday"].map((type) => (
                      <div key={`default-${type}`} className="mb-3">
                        <Form.Check
                          type="checkbox"
                          id={`${type}`}
                          label={`${type}`}
                        />
                      </div>
                    ))}
                  </Form>
                  <Form>
                    {["Thursday", "Friday", "Saturday"].map((type) => (
                      <div key={`default-${type}`} className="mb-3">
                        <Form.Check
                          type="checkbox"
                          id={`${type}`}
                          label={`${type}`}
                        />
                      </div>
                    ))}
                  </Form>
                </div>
              </div>

              <div>
                <div className={styles.childClass}>How many hours per day?</div>
                <div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                    style={{ width: "70%" }}
                  >
                    <Form.Control
                      as="textarea"
                      rows={1}
                      placeholder="Enter hours"
                    />
                  </Form.Group>
                </div>
              </div>
              <div>
                <div className={styles.childClass}>
                  When would you like to start?
                </div>
                <DatePicker
                  selected={startDate}
                  onChange={(date: any) => setStartDate(date)}
                  className={styles.datePicker}
                />
              </div>
            </div>
          </Col>
          <Col lg={true}>
            <PricingEnquiry />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LessonSchedule;
