import Maths from "../assets/images/maths (1).png";
import English from "../assets/images/english.png";
import Science from "../assets/images/chemistry.png";
import Economics from "../assets/images/economics (1).png";
import Languages from "../assets/images/languages.png";
import Geography from "../assets/images/geography.png";
import History from "../assets/images/history.png";
import Music from "../assets/images/music.png";
import Psychology from "../assets/images/tutoring.png";
import Business from "../assets/images/bookclub.png";
import Computer from "../assets/images/computing (1).png";
import Religious from "../assets/images/rs.png";

export const Subjects = [
  {
    id: 1,
    img: Maths,
    description: "Maths",
    backgroundColor: "rgba(255, 235, 206, 0.7)",
    subBackgroundColor: "rgba(255, 225, 181, 0.7)",
  },
  {
    id: 2,
    img: Science,
    description: "Science",
    backgroundColor: "rgba(255, 226, 230, 0.7)",
    subBackgroundColor: "rgba(255, 216, 221, 0.8)",
  },
  {
    id: 3,
    img: English,
    description: "English",
    backgroundColor: "#E3F7FF",
    subBackgroundColor: "#CCF0FF",
  },
  {
    id: 4,
    img: Economics,
    description: "Economics",
    backgroundColor: "rgba(220, 222, 247, 0.5)",
    subBackgroundColor: "rgba(220, 222, 247, 0.6)",
  },
  {
    id: 5,
    img: Languages,
    description: "Languages",
    backgroundColor: "#FDEFFF",
    subBackgroundColor: "#FBE3FF",
  },
  {
    id: 6,
    img: Geography,
    description: "Geography",
    backgroundColor: "#DCFFE8",
    subBackgroundColor: "#BAFFD1",
  },
  {
    id: 7,
    img: History,
    description: "History",
    backgroundColor: "#FFF7DC",
    subBackgroundColor: "#FFEEB6",
  },
  {
    id: 8,
    img: Music,
    description: "Music",
    backgroundColor: "#E7EFFF",
    subBackgroundColor: "#D0E0FF",
  },
  {
    id: 9,
    img: Psychology,
    description: "Psychology",
    backgroundColor: "#FFE9E8",
    subBackgroundColor: "#FFC7C5",
  },
  {
    id: 10,
    img: Business,
    description: "Business Studies",
    backgroundColor: "#FDEFDB",
    subBackgroundColor: "#FEE5C0",
  },
  {
    id: 11,
    img: Computer,
    description: "Computer Science",
    backgroundColor: "#EFF1FF",
    subBackgroundColor: "#D7DBFF",
  },
  {
    id: 12,
    img: Religious,
    description: "Religious Studies",
    backgroundColor: "#E0F6FF",
    subBackgroundColor: " #CCF0FF",
  },
];
