import React from "react";
import { Row, Container, Col, Form, Button } from "react-bootstrap";
import styles from "./Clubs.module.scss";
import Community from "../../assets/images/Group 319.png";
import PublicSpeaking from "../../assets/images/public_speaking.png";
import Coding from "../../assets/images/coding.png";

const Clubs = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col lg={true}>
            <div className={styles.clubs}>NovaTuition Clubs</div>
            <div className={styles.description}>
              Here at NovaTuition, we provide a valuable interactive online
              community. Choose between our Book and Coding Clubs to pick up a
              great new skill but if you can handle a challenge, sign up to
              both!
            </div>
          </Col>
        </Row>
      </Container>

      <div style={{ backgroundColor: "#F6F7FF", paddingTop: "60px" }}>
        <Container>
          <Row>
            <Col lg={true}>
              <div className={styles.bookClub}>
                <img
                  src={Community}
                  alt="community"
                  style={{ width: "100%" }}
                />
              </div>
            </Col>
            <Col lg={true}>
              <div className={styles.range}>
                <div className={styles.book}>Book Club</div>
                <div className={styles.literature}>
                  A range of literature for all ages starting from primary
                  school to A-Level put together by brilliant specialist English
                  tutors. We’ll be working on confidence, reading skills,
                  personal development as well as reading a good book!
                </div>
                <div className={styles.join}>
                  <div className={styles.joinUs}>Join Us</div>
                  <div className={styles.time}>
                    Every{" "}
                    <span className={styles.monday}>Monday from 4:30PM.</span>{" "}
                    Join solo or with friends! Starting from £10 PP.
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container style={{ marginTop: "60px" }}>
          <Row>
            <Col lg={true}>
              <div className={styles.bookClub}>
                <div className={styles.range}>
                  <div className={styles.book}>Coding Club</div>
                  <div className={styles.literature}>
                    In an increasingly digital world, coding is a great skill to
                    have under your belt. We have proficient and experienced
                    tutors on hand to guide you on this online journey.
                  </div>
                  <div className={styles.code}>
                    <div className={styles.joinU}>Join Us</div>
                    <div className={styles.every}>
                      Every
                      <span className={styles.thursday}>
                        Thursday from 6:00PM.
                      </span>
                      Join solo or with friends! Starting from £10 PP.
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={true}>
              <img src={Coding} alt="coding" style={{ width: "100%" }} />
            </Col>
          </Row>
        </Container>
        <Container style={{ marginTop: "70px" }}>
          <Row style={{ paddingBottom: "30px" }}>
            <Col lg={true}>
              <div className={styles.bookClub}>
                <img
                  src={PublicSpeaking}
                  alt="public-speaking"
                  style={{ width: "100%" }}
                />
              </div>
            </Col>
            <Col lg={true}>
              <div className={styles.range}>
                <div className={styles.book}>Public Speaking</div>
                <div className={styles.literature}>
                  Improve your public speaking skills with NovaTuition's fun and
                  encouraging public speaking club - expressing ideas
                  confidently and clearly is one of the most useful skills one
                  can ever develop. Grow in confidence in this encouraging
                  community.
                </div>
                <div className={styles.public}>
                  <div className={styles.joinUsPublic}>Join Us</div>
                  <div className={styles.timePublic}>
                    Every{" "}
                    <span className={styles.mondayPublic}>
                      Monday from 4:30PM.
                    </span>{" "}
                    Join solo or with friends! Starting from £10 PP Join solo or
                    with friends! Starting from £10 PP.
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Clubs;
