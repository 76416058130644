import React, { useState } from "react";
import styles from "./Contactinfo.module.scss";
import { Container, Row, Form, Col, Button } from "react-bootstrap";
import PricingEnquiry from "../../../components/PricingEnquiry/PricingEnquiry";
import MuiPhoneNumber from "material-ui-phone-number";

const ContactInfo = () => {
  const [value, setValue] = useState();
  const [phoneNumber, setPhoneNumber] = useState(0);

  const onChangeNumber = (event: any) => {
    setPhoneNumber(event);
  };
  const inputStyles = {
    background: "rgba(249, 250, 252)",
    fontFamily: "Steradian",
    border: "1px solid #ECF0F4",
    borderRadius: "4px",
    borderStyle: "none",
    color: "#212B4C",
    padding: "8px",
    fontSize: "12px",
    width: "100%",
  };
  return (
    <div>
      <Container>
        <Row>
          <Col lg={true}>
            <div>
              <div className={styles.tellUs}>How can we contact you?</div>
              <div className={styles.choosing}>
                You will be contacted for further updates.
              </div>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={styles.childClass}>
                    Full Names
                  </Form.Label>
                  <Form.Control type="name" style={{ width: "80%" }} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={styles.childClass}>Email</Form.Label>
                  <Form.Control type="email" style={{ width: "80%" }} />
                </Form.Group>
              </Form>

              <div>
                <div className={styles.childClass}>
                  Preferred means of communication
                </div>
                <div className={styles.check}>
                  <Form style={{ display: "flex", flexDirection: "row" }}>
                    {["Email", "Phone Number"].map((type) => (
                      <div key={`default-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label={type}
                          name="group1"
                          type="radio"
                          id={`inline-${type}-2`}
                        />
                      </div>
                    ))}
                  </Form>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={true}>
            <PricingEnquiry />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactInfo;
