const initialAuthState = {
  loginDetails: {
    access_token: "",
    refresh_token: "",
    user_details: {
      user_id: "",
      organization_id: "",
      organization_name: "",
      subscription_id: "",
      team_id: "",
      team_name: "",
      roles: "",
      name: "",
      email: "",
      status: ""
    }
  },
  isLoggedIn: false,
}

const authReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case "SET_LOGIN_DETAILS": {
      return {
        ...state,
        loginDetails: action.loginDetails,
        isLoggedIn: true
      };
    }
    case "LOG_OUT": {
      return {
        ...state,
        loginDetails: {},
        isLoggedIn: false
      };
    }
    default: {
      return state;
    }
  }
};

export default authReducer;
