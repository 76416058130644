import React from "react";
import styles from "./School.module.scss";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import People from "../../assets/images/Group 1133.png";
import Art from "../../assets/images/Rectangle 10.png";
import FormImage from "../../assets/images/grades-17 1.png";
import Marketing from "../../assets/images/marketing.svg";
import MeetingTeam from "../../assets/images/meeting-team.svg";
import Graduation from "../../assets/images/graduation.svg";
import Book from "../../assets/images/book.svg";
import Approved from "../../assets/images/approved.svg";
import Headphones from "../../assets/images/headphones-customer-support-human 1.svg";
import { Subjects } from "../../utils/subjects";

const School = () => {
  return (
    <div>
      <div className={styles.caurosel}>
        <Container>
          <Row>
            <Col lg={true}>
              {" "}
              <div className={styles.tutorsSection}>
                <div className={styles.tutorsLeft}>
                  <div className={styles.bookTutor}>
                    Effective tuition that fits your students' school timetable
                  </div>
                </div>
                <div className={styles.tutorMeeting}>
                  The global pandemic has had an impact on education, which will
                  vary from child to child, from school to school. As teachers,
                  you will be under pressure to enable children to ‘catch up’
                  following this unforeseen disruption to their education. As
                  tutors, we know the impact on children and teenage pupils may
                  be greater than simply missing out on educational content.
                  Young people may be anxious about their future, they may have
                  lost some confidence, they may have lost progress they had
                  made before the school closures.
                </div>
                <div className={styles.tutorStyle}>
                  <div className={styles.tutorText}>
                    {" "}
                    Getting all this back will take a tailored approach, which
                    takes each pupil’s educational level into account, and
                    builds on it.
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.reasons}>
        <Container>
          <Row>
            <Col>
              <div className={styles.nova}>
                <div className={styles.reasonNova}>All subjects areas</div>
              </div>
            </Col>
          </Row>
          <Row>
            {Subjects.map((subject) => (
              <Col
                xs={12}
                md={3}
                className="mb-4"
                key={subject.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className={styles.reasonsSection}
                  style={{
                    backgroundColor: subject.backgroundColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: subject.subBackgroundColor,
                      width: "100px",
                      height: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "14.1818px",
                    }}
                  >
                    {" "}
                    <img
                      src={subject.img}
                      alt="reason"
                      width="50"
                      height="50"
                    />
                  </div>
                  <div className={styles.reasonTitle}>
                    {subject.description}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col
              lg={true}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img src={People} style={{ width: "100%" }} alt="people" />
            </Col>
            <Col
              lg={true}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start ",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "29px",
                    /* identical to box height */

                    letterSpacing: "-0.01em",

                    /* Primary Color */

                    color: "#1A2578",
                  }}
                >
                  Group Practice
                </div>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "18px",
                    lineHeight: "30px",
                    /* or 167% */

                    letterSpacing: "0.04em",

                    /* Primary Color */

                    color: "#1A2578",
                  }}
                >
                  We offer group sessions which help build a sense of teamwork
                  and support your child in an interactive environment.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col
              lg={true}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start ",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "29px",
                    /* identical to box height */

                    letterSpacing: "-0.01em",

                    /* Primary Color */

                    color: "#1A2578",
                  }}
                >
                  Designed Structured Lessons
                </div>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "18px",
                    lineHeight: "30px",
                    /* or 167% */

                    letterSpacing: "0.04em",

                    /* Primary Color */

                    color: "#1A2578",
                  }}
                >
                  We facilitate this by drawing up realistic weekly study
                  timetables,and teaching memory
                </div>
              </div>
            </Col>
            <Col lg={true}>
              <img src={Art} style={{ width: "100%" }} />
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "rgba(196, 202, 255, 0.15)",
          marginTop: "30px",
        }}
      >
        <Container>
          <Row style={{ paddingBottom: "30px", paddingTop: "50px" }}>
            <Col lg={true}>
              <div className={styles.heroImageSection}>
                <img src={FormImage} alt="Hero" style={{ width: "100%" }} />
              </div>
            </Col>
            <Col lg={true}>
              {" "}
              <div className={styles.formSection}>
                <div className={styles.formBook} style={{ width: "100%" }}>
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "24px",
                      lineHeight: "29px",
                      letterSpacing: "0.02em",
                      color: "#1A2578",
                    }}
                  >
                    Book a quick call with our tutors
                  </div>
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "16px",
                      lineHeight: "19px",
                      letterSpacing: "0.02em",
                      marginTop: "6px",
                      color: "#1A2578",
                    }}
                  >
                    Discuss your wants with us.
                  </div>
                </div>
                <Form style={{ width: "100%" }}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "17px",
                        /* identical to box height */
                        letterSpacing: "0.05em",
                        textTransform: "uppercase",

                        /* Primary Color */
                        color: "#1A2578",
                        marginTop: "20px",
                      }}
                    >
                      Full Name
                    </Form.Label>
                    <Form.Control
                      id="name"
                      type="name"
                      name="name"
                      placeholder="Enter Full Name"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "17px",
                        /* identical to box height */
                        letterSpacing: "0.05em",
                        textTransform: "uppercase",

                        /* Primary Color */
                        color: "#1A2578",
                      }}
                    >
                      Email{" "}
                    </Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "17px",
                        /* identical to box height */
                        letterSpacing: "0.05em",
                        textTransform: "uppercase",

                        /* Primary Color */
                        color: "#1A2578",
                      }}
                    >
                      Message
                    </Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default School;
